import React from "react"
import InputSearch from "@components/global/InputSearch"

const RiskAssessment = () => {
  return (
    <div className="input-search-wrapper">
      <div className="input-search">
        <h2 className="input-search-title">Get an Instant Risk Assessment</h2>
        <InputSearch />
      </div>
    </div>
  )
}

export default RiskAssessment

import React from "react"
import { GatsbyImage, getImage} from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"

const OurTeam = ({ team, aboutUsbg }) => {
  
  const image = convertToBgImage(getImage(aboutUsbg.childImageSharp.gatsbyImageData))

  return (
    <>
      <section id="our-team" className="our-team">
        <BackgroundImage
          {...image}
          className="our-team-hero"
        >
          <div className="our-team-hero-content">
            <p>Our Team</p>
            <h2>
              We are science, real estate and technology veterans with a passion
              for consumer advocacy and conservation.
            </h2>
          </div>
        </BackgroundImage>
      </section>
      <section id="our-team-members">
        <div className="our-team-members">
          {team.content.map((ti, index) => {
            return (
              <div className="team-member" key={index}>
                <div className="team-image-container">
                  {ti?.customImage?.image?.asset && (
                    <GatsbyImage
                      image={ti.customImage.image.asset?.gatsbyImageData}
                      alt={ti.customImage.image.asset?.description}
                    />
                  )}
                </div>
                {ti.name && <p className="team-member-name">{ti.name}</p>}
                {ti.grade && <p className="team-member-grade">{ti.grade}</p>}
                {ti.bio && <p className="team-member-bio">{ti.bio}</p>}
              </div>
            )
          })}
        </div>
      </section>
    </>
  )
}

export default OurTeam

import React from "react"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

const Hero = ({ herobg }) => {

  const image = convertToBgImage(getImage(herobg.childImageSharp.gatsbyImageData))

  return (
    <BackgroundImage {...image} className="about-us">
      <div className="about-us-hero">
        <p className="about-us-overline">Our Mission</p>
        <h1 className="about-us-headline">Climate Change Made Tangible</h1>
        <p className="about-us-description">
          We empower property buyers, owners, and brokers by exposing and
          quantifying the risks related to the climate crisis through our
          proprietary risk assessment and report.
        </p>
      </div>
    </BackgroundImage>
  )
}

export default Hero

import React from "react"
import SEO from "@components/SEO"
import CorporativeHeader from "@components/global/CorporativeHeader"
import Hero from "@components/AboutUsPage/Hero"
import InThePress from "@components/HomePage/InThePress"
import FollowUs from "@components/AboutUsPage/FollowUs"
import OurTeam from "@components/AboutUsPage/OurTeam"
import CorporativeFooter from "@components/global/CorporativeFooter"
import PressMentions from "../ui/PressMentions"
import { graphql } from "gatsby"
import "@styles/Components/aboutUs.scss"
import StayUpToDate from "@components/AboutUsPage/StayUpToDate"
import RiskAssessment from "@components/global/RiskAssessment"
import Footer from "@components/global/Footer"

const AboutUsPage = ({
  data: {
    seo,
    team,
    herobg,
    aboutUsbg,
    iconFacebook,
    iconTwitter,
    iconInstagram,
    iconLinkedin,
    allSanityPressMentions,
    allSanityPost,
  },
}) => {
  const customSeo = {
    title: seo?.seo?.title || "About Us",
    link: "https://climatecheck.com/about-us",
    ...seo?.seo,
  }

  return (
    <>
      <SEO seo={customSeo} />
      <CorporativeHeader />
      <Hero herobg={herobg} />
      <InThePress />
      <OurTeam team={team} aboutUsbg={aboutUsbg} />
      <StayUpToDate posts={allSanityPost.edges} />
      <RiskAssessment />
      <Footer />
    </>
  )
}

export default AboutUsPage

export const query = graphql`
  query TeamQuery {
    seo: sanityPagesSeo(slug: { current: { eq: "/about-us" } }) {
      seo {
        title
        keywords
        description
      }
    }
    team: sanityTeamOrder(title: { eq: "Team Order Document" }) {
      content {
        name
        grade
        bio
        customImage {
          image {
            asset {
              gatsbyImageData
              description
            }
          }
        }
      }
    }
    herobg: file(relativePath: { eq: "img-min/Hero-AboutUs-1.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    aboutUsbg: file(relativePath: { eq: "img/Our-Team-bg-min.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    iconTwitter: file(relativePath: { eq: "icons/icon-social-twitter.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    iconFacebook: file(relativePath: { eq: "icons/icon-social-facebook.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    iconLinkedin: file(relativePath: { eq: "icons/icon-social-linkedin.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    iconInstagram: file(
      relativePath: { eq: "icons/icon-social-instagram.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    allSanityPressMentions(
      sort: { fields: publishedAt, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          url
          _id
          customImage {
            image {
              asset {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
                description
              }
            }
          }
          title
          description
          publishedAt(formatString: "YYYY-MM-DD")
          pressName
        }
      }
    }
    allSanityPost(sort: { fields: publishedAt, order: DESC }, limit: 3) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          categories {
            title
          }
          customImage {
            image {
              asset {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
                description
              }
            }
          }
          body {
            children {
              text
            }
          }
          author {
            name
          }
          publishedAt(formatString: "YYYY-MM-DD")
        }
      }
    }
  }
`
